@import 'styles/includes.folkhem.scss';

.CardProject {
    $root: &;

    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;

    &__ShortFacts {
        border-radius: 1px;
        flex: 1 1 auto;
        position: relative;
        background: transparent;
        padding: 22px 0 16px;

        // Set rules for non ie11 browsers
        @supports (display: grid) {
            flex: 1;
        }
    }
}
