@import 'styles/includes.nordr.scss';

.CardProject {
    $root: &;

    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;

    &__ShortFacts {
        border-radius: 1px;
        flex: 1 1 auto;
        margin-top: -25px;
        padding: 0 15px;
        z-index: 1;

        #{$root}--WithinRelatedProjects & {
            padding: 0 16px;

            @include media(xl) {
                padding: 0 24px;
            }
        }

        // Set rules for non ie11 browsers
        @supports (display: grid) {
            flex: 1;
        }
    }
}
